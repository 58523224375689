import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { selectUser } from "../../store/userSlice";
import { getCollections, iCollection } from "../../services/collection";
import { getGroupsImIn, iGroup } from "../../services/groups";
import { loadCollectionProps } from "../../services/collection";
import { debounce } from "lodash";
import {
  PAGE_DIM,
  datetimeToString,
  scrollToTopList,
} from "../../utils/generic";
import CollectionCard from "../../components/collectioncard/CollectionCard";
import Loader from "../../components/dom/Loader";
import Pagination from "../../components/dom/Pagination";

import searchSvg from "../../assets/icons/search.svg";
import { closeModal, openModal } from "../../components/modal/Modal";
import Button from "../../components/dom/Button";
import Select from "../../components/dom/Select";
import { isLoggedIn } from "../../services/auth";
import { PolicyModal } from "../../components/PolicyModal/PolicyModal";

function InvitedNotificationModal() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="p3 text-center">
      <p className="h4">{t("group.invited_modal_title")}</p>
      <p className="">{t("group.invited_modal_text")}</p>

      <div className="d-flex justify-content-center mb-4">
        <Button
          onClick={() => {
            navigate("/account/group");
            closeModal();
          }}
          small
          text={String(t("group.invited_modal_cta"))}
        />
      </div>
    </div>
  );
}

export default function HomeCollections() {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const elementRef = useRef<HTMLDivElement>(null);

  const [collections, setCollections] = useState<Array<iCollection>>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [textQuery, setTextQuery] = useState("");
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState<number>(0);
  const [size, setSize] = useState<number>(PAGE_DIM);
  const [sort, setSort] = useState<any>({ nftCount: -1 });

  const [viewMode, setViewMode] = useState<"block" | "list">("block");

  const { t } = useTranslation();

  const loadCollections = async ({
    page = 1,
    query,
    size,
    sort,
  }: loadCollectionProps) => {
    setIsLoading(true);
    try {
      const data = await getCollections({
        page,
        query,
        size,
        sort,
      });

      setTotal(data.total || 0);

      setCollections(data.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const onSizeChange = (size: number) => {
    setSize(size);
    scrollToTopList(elementRef);
  };

  const onPageChange = (collectionPage: number) => {
    setPage(collectionPage);
    scrollToTopList(elementRef);
  };

  const isInvited = (group: iGroup) => {
    return (
      group.members.find((e) => e.email === user.email)?.isInviteAccepted ===
      false
    );
  };

  const loadGroups = async () => {
    let defaultGroup;
    // setIsLoading(true);

    try {
      const data = await getGroupsImIn();

      defaultGroup = data.filter((g) => g.verified)[0];
      if (
        data.filter((g) => g.verified).length === 1 &&
        !isInvited(defaultGroup)
      ) {
        return navigate("/group/" + defaultGroup._id + "/collection");
      }
    } catch (error) {
      console.log(error);
    }

    // setIsLoading(false);

    return defaultGroup;
  };

  useEffect(() => {
    if (!user._id) return;

    (async () => {
      const defaultGroup = await loadGroups();
      if (defaultGroup && isInvited(defaultGroup))
        openModal(<InvitedNotificationModal />);
    })();

    if (
      (isLoggedIn() && user.TDHid && user.platformPolicy === undefined) ||
      user.platformPolicy === false
    ) {
      openModal(<PolicyModal />, false, true);
    }

    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (!user._id) return;

    loadCollections({ page, size, sort });
    // eslint-disable-next-line
  }, [page, user, size, sort]);

  const searchCallbackDebounced = useRef(debounce(loadCollections, 1000));

  const isQueryGood = (query: string) => {
    return /\S/.test(query) && query.length >= 1;
  };

  const searchInput = (text: string) => {
    if (!text || !isQueryGood(text)) {
      setTextQuery("");
      loadCollections({ page, size });
      return;
    }

    const textQ = {
      $or: [
        { _id: { $regex: text, $options: "i" } },
        { name: { $regex: text, $options: "i" } },
      ],
    };

    searchCallbackDebounced.current({ page, query: textQ });
  };

  return (
    <main id="home-collections">
      <div className="container">
        <div className="page-title">{t("takyon.title")}</div>
        <div className="search-nft">
          <input
            value={textQuery}
            onChange={(e) => {
              setIsLoading(true);
              setTextQuery(e.target.value);
              searchInput(e.target.value);
            }}
            className="w100"
            type="text"
            placeholder={String(t("collection.search_placeholder"))}
          />
          <img src={searchSvg} alt="search icon" className="icon" />
        </div>
        {isLoading ? (
          <div className="d-flex justify-content-center">
            <Loader />
          </div>
        ) : null}

        <div className="d-flex mt-2 gap-3">
          <div className="d-flex flex-column">
            {t("collection.sort_by")}:
            <Select
              onChange={(e: any) => {
                if (e.target.value === "sub_status")
                  setSort({ subscriptionStatus: 1 });
                if (e.target.value === "nft_amount") setSort({ nftCount: -1 });
                if (e.target.value === "name") setSort({ name: 1 });
                if (e.target.value === "last_nft")
                  setSort({ lastNftCreatedAt: +1 });
                if (e.target.value === "recent_nft")
                  setSort({ lastNftCreatedAt: -1 });
              }}
              options={[
                { value: "nft_amount", text: String(t("collection.amount")) },
                { value: "name", text: String(t("collection.coll_name")) },
                { value: "last_nft", text: String(t("collection.last_nft")) },
                {
                  value: "recent_nft",
                  text: String(t("collection.recent_nft")),
                },
                {
                  value: "sub_status",
                  text: String(t("collection.subscription_status")),
                },
              ]}
            />
          </div>
          <div className="d-flex flex-column">
            {t("collection.view_mode")}:{" "}
            <Select
              onChange={(e: any) => {
                setViewMode(e.target.value as any);
              }}
              options={[
                {
                  value: "block",
                  text: String(t("collection.view_mode_block")),
                },
                { value: "list", text: String(t("collection.view_mode_list")) },
              ]}
            />
          </div>
        </div>

        {collections.length === 0 && !isLoading && !textQuery ? (
          <div className="no-collections">
            <p className="message-title">{t("group.no_groups_title")}</p>
            <p className="message">{t("group.no_groups_message")}</p>
            <div className="box-container">
              <div onClick={() => navigate("/account/group")} className="box">
                <span className="box-title">
                  {t("group.option_create_group")}
                </span>
                <span className="box-text">
                  {t("group.option_create_group_text")}
                </span>
              </div>
              <div>{t("group.or")}</div>
              <div onClick={() => navigate("/account/group")} className="box">
                <span className="box-title">
                  {t("group.option_join_group")}
                </span>
                <span className="box-text">
                  {t("group.option_join_group_text")}
                </span>
              </div>
            </div>
          </div>
        ) : null}

        {viewMode === "block" ? (
          <div className="collection-list" ref={elementRef}>
            {collections.map((collection, key) => {
              return (
                <CollectionCard
                  onUpdate={() => loadCollections({ page, size })}
                  key={key}
                  collection={collection}
                />
              );
            })}
          </div>
        ) : null}

        {viewMode === "list" ? (
          <div className="mt-3">
            <table>
              <thead>
                <tr>
                  <td>{t("collection.name")}</td>
                  <td>{t("collection.nfts_amount")}</td>
                  <td>{t("collection.last_nft")}</td>
                  <td>{t("collection.live_date")}</td>
                  <td>{t("collection.subscription_status")}</td>
                </tr>
              </thead>
              <tbody>
                {collections.map((collection, key) => {
                  let subStatus = "";

                  if (
                    collection.trialEndDate &&
                    new Date(collection.trialEndDate) > new Date()
                  )
                    subStatus = "🕙";
                  else
                    switch (collection.subscriptionStatus) {
                      case "active": {
                        subStatus = "🟢";
                        break;
                      }
                      case "trialing": {
                        subStatus = "🟢";
                        break;
                      }
                      case "past_due": {
                        subStatus = "🟡";
                        break;
                      }
                      default: {
                        subStatus = "🔴";
                      }
                    }

                  return (
                    <tr key={"collection_" + key}>
                      <td>{collection.name}</td>
                      <td>{collection.nftCount}</td>
                      <td>{datetimeToString(collection.lastNftCreatedAt)}</td>
                      <td>{datetimeToString(collection.liveDate)}</td>
                      <td>{subStatus}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : null}

        <Pagination
          page={page}
          size={size}
          total={total}
          onSizeChange={onSizeChange}
          onPageChange={onPageChange}
        />
      </div>
    </main>
  );
}
