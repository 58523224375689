import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  acceptInviteGroup,
  createGroup,
  declineInviteGroup,
  getGroupsImIn,
  GROUP_PERMISSIONS,
  iGroup,
} from "../../../services/groups";
import Button from "../../../components/dom/Button";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "../../../store/userSlice";
import { closeModal, openModal } from "../../../components/modal/Modal";

function CreateGroupModal() {
  const { t } = useTranslation();

  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const createGroupSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      const group = await createGroup({ name: name.trimEnd() });

      closeModal();
      navigate("/group/" + group._id);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <form onSubmit={createGroupSubmit} className="p-3">
      <label htmlFor="">{t("group.name")}</label>
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <Button
        type="submit"
        text={String(t("group.create"))}
        loading={isLoading}
        disabled={!name}
      />
    </form>
  );
}

export default function GroupsSettings() {
  const { t } = useTranslation();
  const [groups, setGroups] = useState<Array<iGroup>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const user = useSelector(selectUser);

  const loadGroups = async () => {
    setIsLoading(true);
    try {
      const data = await getGroupsImIn();
      setGroups(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!user._id) return;

    loadGroups();
    // eslint-disable-next-line
  }, [user]);

  const canSeeMemebers = (group: iGroup) => {
    const myPerms = group.members.find(
      (e) => e.email === user.email
    )?.permissions;

    if (!myPerms) return false;

    if (
      myPerms.includes(GROUP_PERMISSIONS.admin) ||
      myPerms.includes(GROUP_PERMISSIONS.memebers) ||
      myPerms.includes(GROUP_PERMISSIONS.view_members)
    )
      return true;

    return false;
  };

  const isInvited = (group: iGroup) => {
    return (
      group.members.find((e) => e.email === user.email)?.isInviteAccepted ===
      false
    );
  };

  const acceptInviteBtn = async (groupId: string) => {
    setIsLoading(true);
    try {
      await acceptInviteGroup({ groupId });
      navigate("/group/" + groupId);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
    loadGroups();
  };

  const declineInviteBtn = async (groupId: string) => {
    setIsLoading(true);
    try {
      await declineInviteGroup({ groupId });
      navigate("/");
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
    loadGroups();
  };

  return (
    <section>
      <Button
        className="ml-auto"
        loading={isLoading}
        onClick={() => openModal(<CreateGroupModal />)}
        text={String(t("group.create"))}
        small
      />

      <span className="title">{t("group.group_list")}</span>
      <div className="group-list">
        {groups.length === 0 ? (
          <div className="group">{t("group.no_groups")}</div>
        ) : null}
        {groups.map((group, key) => {
          return (
            <div
              onClick={() => {
                if (!isInvited(group)) navigate("/group/" + group._id);
              }}
              className={`group clickable`}
              key={"settings_group_" + key}
            >
              <div className="left">
                <div className="name">
                  <span className="h6">{group.name}</span>
                  {!group.verified ? (
                    <>
                      <span> - </span>
                      <span className="not-verified">
                        {t("group.not_verified")}
                      </span>
                    </>
                  ) : null}
                </div>
                {canSeeMemebers(group) ? (
                  <span>
                    {group.members.length} {t("group.members")}
                  </span>
                ) : null}
              </div>
              <div className="right">
                {isInvited(group) ? (
                  <div>
                    <Button
                      onClick={() => acceptInviteBtn(group._id)}
                      className="my-1"
                      text={String(t("group.accept_invite"))}
                      small
                    />
                    <Button
                      onClick={() => declineInviteBtn(group._id)}
                      className="my-1"
                      error
                      text={String(t("group.decline_invite"))}
                      small
                    />
                  </div>
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
}
